@import '~common/ui_imports';

.navigationItem {
  align-items: center;
  border-left: var(--navigation-item-border-width-thickness) solid var(--navigation-background-color);
  color: var(--navigation-text-color);
  display: flex;
  padding: 0.5rem 0;
  text-decoration: none;

  &:hover, &.active {
    background-color: var(--navigation-item-active-background-color);
    color: var(--navigation-text-color);
    text-decoration: none;
    border-color: var(--navigation-item-active-background-color);
  }

  &.active {
    border-color: var(--synth-accent-green); 
  }

  p {
    margin: 0;
  }

  > img {
    margin-left: calc(0px - var(--navigation-item-border-width-thickness));
  }
}

@include media-breakpoint-down(md) {
  .navigationItem {
    padding: 0;

    p {
      display:  none;
    }
  }
}

@keyframes loadingBackground {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
