@import '~common/ui_imports';

.accountSettingsNavigationItem {
  display: flex;
  padding: 0.5rem 0;
  width: 100%;
  background: transparent;
  border: 0;
  color: var(--navigation-text-color);
}

.accountDetails {
  flex: 1;
  min-width: 0;
  text-align: left;

  p:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }
}

.text {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.boldedText {
  font-weight: var(--synth-font-weight-bold);
}

.avatar {
  text-align: center;
  width: var(--page-initial-sidebar-width);

  span {
    @include font-type-40;

    align-items: center;
    background-color: #162C4E;
    border-radius: 50%;
    color: var(--ux-white);
    display: flex;
    height: 2.25rem;
    justify-content: center;
    margin: auto;
    width: 2.25rem;
  }
}

.accountSettingsNavigationItemContainer {
  position: relative;

  .accountMenuPopoverContainer {
    position: absolute;
    width: 15rem;
  }
}

@include media-breakpoint-up(md) {
  .accountSettingsNavigationItem {
    &:hover {
      background-color: var(--navigation-item-active-background-color);
    }
  }

  .accountSettingsNavigationItemContainer {
    .accountMenuPopoverContainer {
      bottom: 0;
      left: 100%;
      padding-left: 1rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .accountSettingsNavigationItem {
    height: auto;

    .accountNavItemText {
      display: none;
    }
  }

  .accountSettingsNavigationItemContainer {
    .accountMenuPopoverContainer {
      right: 0;
      top: 100%;
      padding-top: 1.25rem;
    }
  }

  .avatar span {
    @include font-type-20;
  }

  .accountNavItemText, .accountSettingsNavigationItem {
    padding: 0;
  }
}
